<!--热门餐厅列表-->
<template>
    <div class="hot-wrapper">
        <img class="hot-banner" src="https://seetop-1257860468.file.myqcloud.com/html/img/hot_banner0915.jpg">
        <div class="hot-tab">
            <div @click="switchTab('周二五折')" :class="currentTab == '周二五折' ? 'hot-tab-active' : ''">周二五折</div>
            <div @click="switchTab('活动回顾')" :class="currentTab == '活动回顾' ? 'hot-tab-active' : ''">活动回顾</div>
        </div>
        <van-list
                v-model="listLoading"
                :finished="listLoadingFinished"
                finished-text="没有更多了"
                @load="onLoad"
                v-if="listLoadingList.length > 0"
        >
            <div class="hot-item-wrapper" v-for="(item, index) in listLoadingList" :key="index" @click="viewArticle(item.link)">
                <div class="hot-img-wrapper">
                    <img class="hot-img" :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + item.img">
                </div>
                <div class="hot-footer-wrapper">
                    <div class="hot-info-wrapper">
                        <div>{{item.title}}</div>
                        <div>{{item.desc}}</div>
                    </div>
                    <div class="hot-default-button" v-if="currentTimestamp > standardTime2Timestamp(item.online_end)">
                        {{item.online_end.slice(0,11)}}已结束
                    </div>
                    <div class="hot-default-button" v-else-if="currentTimestamp < standardTime2Timestamp(item.online_start)">
                        {{item.online_start.slice(0,11)}}开启
                    </div>
                    <div class="hot-btn" @click.stop="getHotQr(item.id,item.sign)" v-else>
                        <div>五折</div>
                        <div>买单</div>
                    </div>
                </div>
            </div>
        </van-list>
        <van-popup v-model="showQr">
            <div class="qr-wrapper">
                <vue-qr id="qr" :text="qrUrl" ></vue-qr>
                <div>向商家出示二维码进行核销</div>
            </div>
        </van-popup>
        <float-nav></float-nav>
    </div>
</template>

<script>
  import FloatNav from '../../components/common/FloatNav'
  import { globalConfig, listLoading } from '../../utils/mixin'
  import VueQr from 'vue-qr'
  import { getCurrentTimestamp, standardTime2Timestamp } from '../../utils/tools'
  export default {
    name: 'HotList',
    mixins: [globalConfig, listLoading],
    components: { FloatNav, VueQr },
    data () {
      return {
        currentTab: '周二五折',
        status: 1,
        isCardUser: 0,
        showQr: false,
        cardNumber: '',
        qrUrl: ''
      }
    },
    methods: {
      standardTime2Timestamp,
      switchTab: function (tab) {
        this.currentTab = tab
        if (tab === '周二五折') {
          this.status = 1
        } else if (tab === '活动回顾') {
          this.status = 0
        }
        this.listLoadingInit(this.apiUrl + 'mall/hot/getHotList', 'list', 1, {
          status: this.status,
          type: 'hot'
        })
      },
      init: function () {
        const that = this
        this.axios.post(this.apiUrl + 'mall/init/hot').then((response) => {
          const data = response.data
          that.isCardUser = data.isCardUser
          that.cardNumber = data.cardNumb
        })
      },
      viewArticle: function (url) {
        console.log(url)
        if (url) {
          window.location.href = url
        }
      },
      // 点击获取qr弹窗
      getHotQr: function (id, sign) {
        if (this.isCardUser === 1) {
          this.qrUrl = this.webUrl + 'web/checkh5/checkHotv2.html?type=5&cardnum=' + this.cardNumber + '&id=' + id + '&sign=' + sign
          this.showQr = true
        } else {
          this.$toast('您不是碗卡/月卡，无法参与热门餐厅')
        }
      }
    },
    computed: {
      currentTimestamp: function () {
        return getCurrentTimestamp()
      }
    },
    created () {
      this.init()
      this.switchTab('周二五折')
    }
  }
</script>

<style scoped>
    .hot-wrapper {
        position:absolute;
        min-height: 100%;
        min-width: 100%;
        background-color:#efefef;
    }
    .hot-banner {
        width: 100%;
    }
    .hot-tab {
        width: 90%;
        margin: 1rem auto;
        display: flex;
        flex-flow: row;
    }
    .hot-tab div{
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 0;
        font-size: 0.9rem;
        box-shadow: 0 0 0.1rem #cccccc;
    }
    .hot-tab-active {
        color: #333;
        background-color: #FEE851;
        font-weight: 600;
    }
    .hot-item-wrapper {
        display: block;
        width: 90%;
        margin: 1rem auto;
        background-color: #ffffff;
        padding: 1.8vw;
    }
    .hot-img-wrapper {
        width: 100%;
        max-height: 13rem;
        overflow: hidden;
    }
    .hot-img {
        width: 100%;
    }
    .hot-footer-wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.5rem;
    }
    .hot-info-wrapper {
        width: 68%;
        display: flex;
        flex-flow: column;
    }
    .hot-info-wrapper div:first-child{
        font-size: 1rem;
        letter-spacing: 0.3rem;
        margin-left: 0.5rem;
        color: #000000;
    }
    .hot-info-wrapper div:nth-child(2) {
        font-size: 0.85rem;
        color: #7c7c7c;
        margin-left: 0.5rem;
    }
    .hot-btn {
        display: flex;
        flex-flow: row;
        font-size: 0.8rem;
        width: 30%;
        height: 2.2rem;
        background: url("https://seetop-1257860468.file.myqcloud.com/html/img/hotbtn202009102.png") no-repeat;
        background-size: 100% 100%;
    }
    .hot-btn div {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hot-btn div:first-child{
        color: #ff3b30;
    }
    .hot-btn div:nth-child(2){
        color: #000000;
    }
    .qr-wrapper {
        text-align: center;
        padding: 0 0 1rem 0;
    }
    .hot-default-button {
        font-size: 0.8rem;
        width: 30%;
        text-align: center;
        color: #ff3b30;
    }
</style>
