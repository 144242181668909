import { render, staticRenderFns } from "./HotList.vue?vue&type=template&id=49c8eb1c&scoped=true&"
import script from "./HotList.vue?vue&type=script&lang=js&"
export * from "./HotList.vue?vue&type=script&lang=js&"
import style0 from "./HotList.vue?vue&type=style&index=0&id=49c8eb1c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49c8eb1c",
  null
  
)

export default component.exports